<template>
  <div>
    <!-- ads modal -->
    <customer-ads-modal />
    <v-app-bar id="myDiv" color="red accent-3" dark dense class="mb-5" flat>
      <v-avatar class="mr-4 pa-2" size="40">
        <img src="@/assets/jmbcircle.png" alt="JMB"
      /></v-avatar>
      <v-toolbar-title
        ><h2 class="overline">JMBComputers Online Portal</h2></v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="menu_action('send_a_message')">
            <v-icon class="mr-2">mdi-mailbox-open-outline</v-icon>
            Send a message
          </v-list-item>
          <v-list-item @click="menu_action('about')">
            <v-icon class="mr-2">mdi-information-outline</v-icon>
            About
          </v-list-item>
          <v-list-item @click="menu_action('sign_out')">
            <v-icon class="mr-2">mdi-logout-variant</v-icon>
            Sign-out
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-container>
      <!-- content -->
      <customer-info :customer="customer" />
    </v-container>
    <Notification />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import AboutModal from "../components/Customer/aboutModal.vue";
import CustomerAdsModal from "../components/Customer/customerAdsModal.vue";
import customerInfo from "../components/Customer/customerInfo.vue";
import Notification from "../components/Notification.vue";
export default {
  components: { customerInfo, CustomerAdsModal, Notification },
  data() {
    return {
      open_about: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
      right: null,
    };
  },
  methods: {
    ...mapActions({
      customer_signout: "customer/customer_signout",
      set_notification: "notification/set_notification",
    }),
    menu_action(action) {
      if (action != "sign_out")
        this.set_notification({
          message: "This feature is not ready yet",
          type: "primary",
        });
      else {
        this.customer_signout().then(() => {
          this.set_notification({
            message: "You have successfully logged out",
            type: "primary",
          });
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      customer: "customer/customer",
    }),
  },
  created() {
    window.onload = function () {
      // Get the position of the div you want to scroll to
      var divPosition = document.getElementById("myDiv").offsetTop;

      // Scroll to the div
      window.scrollTo(0, divPosition);
    };
  },
};
</script>

<style></style>
