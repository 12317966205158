<template>
  <div>
    <v-dialog v-model="dialog" persistent width="100%">
      <v-card dark>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-img src="@/assets/LAPTOP.jpg" max-width="100%"></v-img>
          <div class="text-center">
            <h2 class="mt-2">
              Get your laptop fixed at a very affordable price!
            </h2>
            <v-divider class="mb-2 mt-2"></v-divider>
            <p class="mt-2">
              Note: Prices for repair may vary from problem to problem. It is
              best advised to bring your laptop to our physical store for
              diagnosis.
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="dialog = false" color="primary"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close_add", true);
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style lang="scss" scoped></style>
