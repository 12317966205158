<template>
  <v-container>
    <v-card max-width="90%" class="ma-auto">
      <v-card-text class="text-center">
        <v-row>
          <v-col cols="" class="mr-2">
            <v-avatar color="primary" class="pa-2" size="70"
              ><p class="display-1 white--text font-weight-bold mt-4">
                {{ customer.customer_name.charAt(0) }}
              </p>
            </v-avatar>

            <h3 class="black--text font-weight-bold mt-2">
              {{ customer.customer_name }}
            </h3>
            <v-divider class="mt-2 mb-2"></v-divider>
            <p class="grey--text font-weight-regular caption">
              Contact Number: {{ customer.customer_contact_no }}
            </p>
            <p class="grey--text font-weight-regular caption mt-n4">
              Municipality: {{ customer.customer_municipality }}
            </p>
            <p class="grey--text font-weight-regular caption mt-n4">
              Barangay: {{ customer.customer_barangay }}
            </p>
            <p class="grey--text font-weight-regular caption mt-n4">
              Purok: {{ customer.customer_purok }}
            </p>
            <p class="grey--text font-weight-regular caption mt-n4">
              Other Contact Platform: {{ customer.other_contact_platform }}
            </p>
            <p class="grey--text font-weight-regular caption mt-n4">
              Where did you find us?: {{ customer.where_did_you_find_us }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="mt- mb-1"></v-divider>

        <div>
          <v-btn class="mr-2 mb-2" text color="primary" small @click="open_home"
            ><v-icon>mdi-home</v-icon>Home</v-btn
          >
          <units-view :units="customer.units" />
          <invoice-view :invoices="customer.invoices" />
          <settings-view />
        </div>
        <div class="text-center mt-5">
          <p class="caption mt-n2 grey--text">
            Customer Service: 09810830882 | www.facebook.com/JMBComputersN
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import InvoiceView from "./invoiceView.vue";
import SettingsView from "./settingsView.vue";
import unitsView from "./unitsView.vue";
export default {
  methods: {
    open_home() {
      let x = window.confirm(
        "This will redirect you to our Official Facebook Page. Are you sure you want to proceed?"
      );
      if (x) window.open("https://www.facebook.com/JMBComputersN");
    },
  },
  components: { unitsView, InvoiceView, SettingsView },
  props: ["customer"],
};
</script>

<style></style>
