<template>
  <div>
    <v-btn class="mr-2 mb-2" text color="grey" small @click="dialog = true"
      ><v-icon>mdi-cog</v-icon>Settings</v-btn
    >
    <v-dialog v-model="dialog" persistent width:500>
      <v-card>
        <v-sheet color="grey"
          ><h1 class="pa-2 white--text">Account Settings</h1></v-sheet
        >

        <v-card-text>
          <p class="mt-2">You can change your system preferences here.</p>
          <v-btn @click="action('change_account_info')"
            >Change Account Info</v-btn
          >
          <v-btn class="mt-2" @click="action('change_profile_picture')"
            >Change Profile Picture</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notification />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Notification from "../Notification.vue";
export default {
  components: { Notification },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),
    action(action) {
      if (action)
        this.set_notification({
          message: "This feature is not ready yet",
          type: "info",
        });
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
