<template>
  <div>
    <v-btn class="mr-2 mb-2" text color="warning" small @click="dialog = true"
      ><v-icon>mdi-laptop</v-icon>Units</v-btn
    >
    <v-dialog v-model="dialog" persistent width:500>
      <v-card>
        <v-sheet color="warning"
          ><h1 class="pa-2 ml-4 white--text">Units</h1></v-sheet
        >
        <v-card-text>
          <div v-for="unit in units" :key="unit.id" class="mt-3 mb-7">
            <h2>
              <v-icon class="mr-2" large>{{
                unit.unit_type == "Laptop"
                  ? "mdi-laptop"
                  : unit.unit_type == "Dekstop"
                  ? "mdi-computer"
                  : "mdi-printer"
              }}</v-icon>
              {{ unit.unit_type }}
            </h2>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-row class="">
              <v-col>
                <p>Model: {{ unit.unit_model }}</p>
                <p class="mt-n3">Brand : {{ unit.unit_brand }}</p>
                <p class="mt-n3">Date Received : {{ unit.date_received }}</p>
              </v-col>
            </v-row>
            <v-divider class="mt-1 mb-1"></v-divider>
            <h3>Problem Statement</h3>
            <!-- PROBLEMS -->
            <div v-for="problem in unit.problems" :key="problem.id">
              <!-- INITIAL PROBLEM -->
              <p class="mt-2">
                Initial Problem:
                <strong>{{ problem.problem_description }}</strong>
              </p>
              <h4 class="warning--text mb-4">Actions Performed:</h4>
              <ol>
                <li
                  v-for="actions in JSON.parse(problem.actions_performed)"
                  :key="actions"
                >
                  {{ actions }}
                </li>
              </ol>
              <!-- <div
                 
                >
                  <p class="mt-n3">{{ actions }}</p>
                </div> -->
              <!-- RESULTS -->
              <!-- 
              <h4 class="green--text mb-4">Results:</h4>
              <ol>
                <li
                  v-for="recommendation in JSON.parse(problem.recommendations)"
                  :key="recommendation"
                >
                  {{ recommendation }}
                </li>
              </ol> -->

              <!-- REMARKS -->
              <h4 class="blue--text mb-4">Remarks:</h4>
              <ol>
                <li
                  v-for="remark in JSON.parse(problem.other_remarks)"
                  :key="remark"
                >
                  {{ remark }}
                </li>
              </ol>

              <!-- FOOTERS -->
              <h4>
                Status:
                <span
                  :class="
                    problem.status == 'Fixed'
                      ? 'green--text font-weight-bold'
                      : problem.status == 'Pending'
                      ? 'warning--text font-weight-bold'
                      : 'error--text font-weight-bold'
                  "
                  >{{ problem.status }}</span
                >
              </h4>
              <h4>Technician: {{ problem.technician }}</h4>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["units"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
